@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?iz5of');
  src:  url('../fonts/icomoon.eot?iz5of#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?iz5of') format('truetype'),
    url('../fonts/icomoon.woff?iz5of') format('woff'),
    url('../fonts/icomoon.svg?iz5of#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"]::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  content: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 
.icon-tablet:before {
  content: "\e901";
  color: #fff;
}
.icon-cube:before {
  content: "\e902";
  color: #fff;
}
.icon-seaweed:before {
  content: "\e903";
  color: #b8b8b8;
}
.icon-rorschach:before {
  content: "\e904";
  color: #fff;
  margin-right: 10px;
}
.icon-triangle:before {
  content: "\e906";
  content: unset;
  color: #515156;
  margin-right: 10px;
}
.icon-shell:before {
  content: "\e907";
  color: #fff;
}
.icon-rocket:before {
  content: "\e908";
  color: #fff;
}
.icon-brains:before {
  content: "\e909";
  color: #fff;
}
.icon-hand:before {
  content: "\e90a";
  color: #fff;
}
.icon-neuron:before {
  content: "\e90b";
  color: #fff;
}
.icon-forum:before {
  content: "\e900";
  color: #fff;
}
.icon-project:before {
  content: "\e901";
  color: #fff;
}
.icon-twitter:before {
  content: "\e902";
  color: #fff;
} */
