.ethBtn{
    /* background: ; */
    margin-right: 10px;
    min-width: auto;
    cursor: default;
}

.ethBtn:after,
.ethBtn::before{
    content: unset!important;
}

.ethBtn *{
    background-color: rgb(81, 81, 86) !important;
    color: #FFFFFF !important;
}