.loader {
    position: relative;
    display: block;
    width: 40%;
    max-width: 300px;
    /* height: 100%; */
  }

.line {
    /* height: 1vh; */
    min-height: 5px;
    background: #ccc;
    margin-bottom: 5px;
    animation: 4s load ease-in-out infinite;
  }
  .line:nth-of-type(2) {
    animation-delay: -0.1s;
  }
  .line:nth-of-type(3) {
    animation-delay: -0.2s;
  }
  .line:nth-of-type(4) {
    animation-delay: -0.3s;
  }
  .line:nth-of-type(5) {
    animation-delay: -0.4s;
  }
  .line:nth-of-type(6) {
    animation-delay: -0.5s;
  }
  .line:nth-of-type(7) {
    animation-delay: -0.6s;
  }
  .line:nth-of-type(8) {
    animation-delay: -0.7s;
  }
  .line:nth-of-type(9) {
    animation-delay: -0.8s;
  }
  .line:nth-of-type(10) {
    animation-delay: -0.9s;
  }
  .line:nth-of-type(11) {
    animation-delay: -1s;
  }
  .line:nth-of-type(12) {
    animation-delay: -1.1s;
  }
  .line:nth-of-type(13) {
    animation-delay: -1.2s;
  }
  .line:nth-of-type(14) {
    animation-delay: -1.3s;
  }
  .line:nth-of-type(15) {
    animation-delay: -1.4s;
  }
  .line:nth-of-type(16) {
    animation-delay: -1.5s;
  }
  .line:nth-of-type(17) {
    animation-delay: -1.6s;
  }
  .line:nth-of-type(18) {
    animation-delay: -1.7s;
  }
  .line:nth-of-type(19) {
    animation-delay: -1.8s;
  }
  .line:nth-of-type(20) {
    animation-delay: -1.9s;
  }
  .line:nth-of-type(21) {
    animation-delay: -2s;
  }
  .line:nth-of-type(22) {
    animation-delay: -2.1s;
  }
  @keyframes load {
    0% {
      width: 25%;
      margin-left: 75%;
    }
    25% {
      width: 1%;
      margin-left: 50%;
    }
    50% {
      width: 25%;
      margin-left: 0;
    }
    75% {
      width: 1%;
      margin-left: 50%;
    }
    100% {
      width: 25%;
      margin-left: 75%;
    }
  }