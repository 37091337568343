.loading{
    height: 100%;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 4;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagination{
    grid-column-start: 1;
    grid-column-end: 4;
    display:grid;
    grid-template-columns: repeat(3,1fr);
    margin-top: auto;
    align-items: center;
}

.pagination button{
    padding:  10px;
    position: absolute;
}

.pagination div{
    /* display: none; */
}

.pagination button:nth-child(1){
    left: 0px;
    top: 50%;
    z-index: 5;
    color: red;
    transform: translateY(-50%) scale(-1 ,1 );

}

.pagination button:nth-child(3){
    right: 0px;
    top: 50%;
    z-index: 5;
    color: red;
    transform: translateY(-50%);
}

.content__list{
    min-height: 400px;
    margin-left: 40px;
    margin-right: 40px;
    align-items: flex-start;
}

@media screen and (max-width:900px) {
    .pagination,.loading{
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .content__list{
        min-height: 666px;
    }
}

@media screen and (max-width:600px) {
    .pagination{
        position: relative;
    }

    .pagination,.loading{
        grid-column-start: 1;
        grid-column-end: 2;
    }
    .content__list{
        margin-left: 20px;
        margin-right: 20px;
    }

    .pagination button:nth-child(1){
        left: 0px;
        top: 50%;
        /* bottom: 0; */
        z-index: 5;
        color: red;
        /* transform: scale(-1 ,1 ); */
    
    }
    
    .pagination button:nth-child(3){
        right: 0px;
        top: 50%;
        /* top: auto; */
        /* bottom: 0; */
        z-index: 5;
        color: red;
        /* transform: translateY(-50%); */
    }
}

@media screen and (max-width:400px) {
    .content__list{
        margin-left: 10px;
        margin-right: 10px;
    }
}
@media screen and (max-width:350px) {
    .content__list{
        margin-left: 0px;
        margin-right: 0px;
    }
}

.paginationRow{
    display: flex;
    justify-content: center;
    gap: 1px;
    grid-column-start: 1;
    grid-column-end: 4;
    /* grd */
    /* width: 100%; */
}

.paginationPoint{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: auto;
    /* width: 8px; */
    /* height: 8px; */
    padding: 2px;
    /* box-sizing: content-box; */
    border-radius: 100%;
    /* border: 1px solid red; */
    /* background-color: red; */
    cursor: pointer;
}

.paginationPoint::after{
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #FFF;
    opacity: 0.6;
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
}

.paginationPoint:hover::after{
    opacity: 1;
}

.paginationPoint.active{
    
    pointer-events: none;
}

.paginationPoint.active::after{
    opacity: 1;
    background-color: #fff;
}