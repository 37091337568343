.footer{
    background-color: #fff;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    /* padding-left: 2.5rem; */
    /* padding-right: 2.5rem; */
    padding-top: 7.5rem;
    padding-bottom: 4rem;
    position: relative;
}

.footer__h2{
    color: #000;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    font-family: "Satoshi";
    line-height: 1.5;
    margin-bottom: .85rem;
}

.footer__h3{
    text-align: center;
    font-size: 47px;
    font-weight: 700;
    color: #000;
    font-family: "Satoshi";
    /* font-family: Satoshi, sans-serif; */
    line-height: 1.2;
    margin-bottom: .85rem;
}

.demoBtn .crcl_animate_inner{
    background-color: red;
    border: 0px solid rgba(255, 255, 255,1)!important;
    /* background-color: red!important; */
}

.footer__p{
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    font-family: "Satoshi";
    /* font-family: Satoshi, sans-serif; */
    line-height: 1.5;
    /* text-align: center;
    font-size: 47px;
    font-weight: 700;
    color: #ffffff;
    font-family: Satoshi, sans-serif;
    line-height: 1.2;
    margin-bottom: .85rem; */
}

.footer__divider{
    margin-top: 7rem;
    border-color: #373e46; 
}

.footer__p2{
    font-size: 1rem;
    color: #000;
    /* opacity: .5; */
    /* font-family: Satoshi, sans-serif; */
}

.footer__bottom{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 4rem;
    justify-content: space-between;
    font-weight: 500;
}

.footer__nav_list{
    display: flex;
    gap: 1rem
}

.footer__list_item{
    font-size: 1rem;
    color: #000;
    font-weight: 600;
    /* font-family: Satoshi, sans-serif; */
}


.footer__btn_container{
    display: flex;
    justify-content: center;
    margin-top: 2.85rem;
    gap: 1rem;
}

.footer__btn_container a{
    height: auto;
    line-height: 1!important;
}

.footer__btn_container a *{
    line-height: 1!important;
}

.footer__btn_container{
    display: flex;
    flex-wrap: wrap;
}