.container {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

._pc {
  display: block !important;
}

._mob {
  display: none !important;
}

.green {
  color: #ffffff;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 0;
  /* padding: 24px 0; */
  background: #000;
  /* rgba(255, 255, 255, 0.1); */
  /* background-color: red; */
  /* backdrop-filter: blur(50px); */
  /* -webkit-backdrop-filter: blur(50px); */
  z-index: 15;
}

.header__burger {
  position: relative;
  display: block;
  width: 35px;
  height: 35px;
  background: transparent;
}

.header__burger:hover {
  cursor: pointer;
}

.header__burger span {
  transform-origin: center;
  display: block;
  background: #fff;
  width: 100%;
  height: 3px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.8s;
}

.header__burger::after,
.header__burger::before {
  transition: all 0.8s;
  transform-origin: center;
  content: "";
  width: 100%;
  height: 3px;
  background: #fff;
  position: absolute;
}

.header__burger::after {
  top: 50%;
  transform: translateY(-10px);
}

.header__burger::before {
  top: 50%;
  transform: translateY(8px);
  /* bottom: 0; */
}

.header__burger.active span {
  transform: scale(0.2) translateY(-50%);
  opacity: 0;
}

.header__burger.active::after {
  top: 50%;
  transform: rotate(45deg);
}

.header__burger.active::before {
  top: 50%;
  transform: rotate(-45deg);
}

.header__body {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  /* repeat(3,1fr); */
  justify-content: space-between;
  align-items: center;
}

.header__title {
  font-family: "Kulim Park";

  /*  */
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  font-size: 24px;
  line-height: 32px;
  color: #FFFFFF;
}

.header__btns {
  display: flex;
  align-items: center;
  position: relative;
}

.mobile_custom{
  display: none!important;
}

.header__btns:last-of-type{
  justify-content: flex-end;
}

.header__btn {
  border-radius: 100px;
  position: relative;
  display: block;
}

.header__btn:first-child {
  margin-right: 16px;
}

.header__btn-border {
  
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  background: transparent;
  /* padding: 10px 61px 10px 24px; */
}

.header__btn-border:hover {
  color: #e6e6e6;
  border: 1px solid red #e6e6e6;
}

.header__btn-border::after {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 14px;
  background: url("../../assets/img/arrow_down.svg") center no-repeat;
  transform: translateY(-50%);
}

.header__btn-fill {
  background: #fff;
  padding: 8px 11px;
  /* padding: 10px 63px 10px 35px; */
  display: flex;
  align-items: center;
}

.header__btn-not-fill{
  font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 72px;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    background: transparent;
    text-transform: uppercase;
    padding: 8px 23px 8px 23px;
}

.header__btn-fill::before {
  font-size: 28px;
  color: #515156;
}

.header__btn-fill span {
  
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #4D4E58;
  font-size: 12px;
  line-height: 20px;
}

.header__btn-fill:hover {
  background: #e6e6e6;
}

.header__dropdown {
  position: absolute;
  min-width: 266px;
  top: 100%;
  left: -10%;
  background: #515156;
  border-radius: 8px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.12);
  padding: 8px 0;
  opacity: 0;
  transition: all 0.2s;
}

.header__dropdown-item {
  
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 8px 24px;
  background: #515156;
}

.header__dropdown-item:hover {
  cursor: pointer;
  background: #28282d;
}

.header__dropdown.active {
  transform: translateY(10%);
  opacity: 1;
}

.vertical__text {
  position: absolute;
  font-family: "Tomorrow";
  font-style: normal;
  /* font-weight: 700; */
  font-weight: 400;
  font-size: 48px;
  line-height: 56px;
  font-size: 120px;
  line-height: 96px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #FFFFFF;
  z-index: 1;
}

.bottom_to_top {
  writing-mode: vertical-lr;
  top: 0;
  left: 0;
  transform-origin: center;
  transform: rotate(180deg);
}

.top_to_bottom {
  writing-mode: vertical-rl;
  right: 0;
  top: 0;
}

.exo {
  font-family: 'Satoshi' !important;
}



@media screen and (min-width: 300px) and (max-width: 1250px) {


  .header__btn-border{
    padding-left: 45px!important;
  }
  .header__btn-not-fill{
    font-size: 18px!important;
  }

  ._pc {
    display: none !important;
  }

  .mobile_custom{
    display: block!important;
  }

  .header__btn{
    justify-content: center;
  }

  .header__dropdown{
    left: 0px!important;
  }

  ._mob {
    display: block !important;
  }

  .container {
    width: 95%;
  }

  .header__dropdown,
  .header__dropdown-item{
    background: transparent !important;
  }

  .header {
    /* background: rgba(255, 255, 255, 0.4); */
  }

  .header__body {
    display: flex;
    position: relative;
  }

  .header__title {
    width: 100%;
    text-align: center;
  }

  .header__dropdown {
    position: relative;
    left: 0;
    width: 80%;
    display: none;
    transition: all 0.5s;
    box-shadow: none;
  }

  .header__dropdown.active {
    display: block;
  }

  .header__btns {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #000;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    top: 60px;
    width: 100%;
    opacity: 0;
    transition: all 0.2s;
    display: none;
    left: -2.6%;
    padding: 50px 0;
  }

  .header__btns.active {
    display: flex;
    opacity: 1;
  }

  .header__btn {
    width: 80%;
    font-size: 18px;
    margin: 0;
    margin-top: 20px;
  }

  .header__btn:first-child {
    margin: 0;
  }

  .vertical__text {
    display: none;
  }
}

@media screen and (min-width: 781px) and (max-width: 1250px) {
  ._pc {
    display: block !important;
  }


  ._mob {
    /* display: none !important; */
  }
}

@media screen and (min-width: 2500px) {
  .header {
    padding: 44px;
  }

  .header__title {
    font-size: 44px;
  }

  .header__dropdown {
    left: -15%;
  }

  .header__dropdown-item {
    font-size: 32px;
    line-height: 44px;
    padding: 16px 217px 16px 60px;
  }

  .header__btn-border {
    padding: 20px 70px 20px 50px;
    font-size: 32px;
  }

  .header__btn-border::after {
    background-size: cover;
    width: 44px;
    height: 44px;
    transform: translateY(30%);
  }

  .header__btn-fill {
    padding: 20px 50px;
  }

  .header__btn-fill::before {
    font-size: 44px;
    margin-right: 10px;
  }

  .header__btn-fill span {
    font-size: 32px;
  }
}

.wrapper {
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  background: #000;
  /* overflow-x: hidden; */
  /* background-image: url("../../assets/img/bg_sign-in.png"); */
  background-position: 100% 0%;
  background-size: 50%;
  background-repeat: no-repeat;
}

.gradient_rectangle_line {
  width: 25%;
  background: linear-gradient(264.23deg, #00C4B8 4.42%, #0ACE88 98.24%);
  opacity: 0.8;
  position: absolute;
  top: 0;
  right: 0;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 254px;
}

.form__body {
  min-width: 23%;
  display: flex;
  flex-direction: column;
  padding-bottom: 200px;
}

.form__title {
  
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #FFFFFF;
  margin-bottom: 60px;
}

.form__label {
  display: flex;
  flex-direction: column;
  
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
}

.form__input {
  display: block;
  background: #515156;
  border-radius: 6px;
  color: #C4C4C4;
  padding: 12px 24px;
  margin-top: 8px;
  margin-bottom: 24px;
}

.form__button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #FFFFFF;
  margin-top: 60px;
  border-radius: 100px;
}

.form__button::before {
  color: #FFFFFF;
  /* opacity: 0.5; */
  font-size: 40px;
}

.form__button:hover {
  background: rgba(53, 156, 251, 0.7);
}

@media screen and (min-width: 300px) and (max-width: 780px) {
  .form {
    padding-top: 120px;
  }

  .wrapper {
    background-size: 70%;
    background-position: 100% 30%;
  }

  .gradient_rectangle_line {
    display: none;
  }

  .vertical__text {
    display: none;
  }

  .form__body {
    width: 80%;
  }
}

@media screen and (min-width: 781px) and (max-width: 1250px) {
  .form__body {
    width: 40%;
  }
}

@media screen and (min-width: 2500px) {
  .form__body {
    width: 39%;
  }

  .form__title {
    font-size: 63px;
  }

  .form__label {
    font-size: 24px;
  }

  .form__input {
    font-size: 24px;
    padding: 20px 30px;
  }

  .form__button {
    padding: 20px 30px;
    font-size: 24px;
  }

  .form__button::before {
    font-size: 50px;
    margin-right: 10px;
  }
}


/*Обнуление*/
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}

/*--------------------*/

.bigred {
  font-size: 20px;
  color: red;
}

