.longevity__grid-head{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 40px;
}

@media screen and (max-width:950px){
  .longevity__grid-head{
    grid-template-columns: 1fr
  }
  .longevity__grid__col:first-of-type{
    align-items: center;
  }

  .longevity__poster img{
    max-width: 100%;
    height: auto;
    /* height: 400px; */
  }
}
@media screen and (max-width:1250px){
  .longevity__row:first-of-type{
    flex-direction: row!important;
    align-items: center;
  }

}

@media screen and (max-width:768px){
  .longevity__block-price,
  .contribution__title,
  .longevity__title{
    font-size: clamp(2.25rem, 1.7143rem + 2.6786vw, 3rem)!important;
  }

  .longevity__row:first-of-type{
    flex-direction: row!important;
    align-items: center;
  }

  .longevity__title{
    margin: 0!important;
  }

  .contribution__body{
    padding: 20px!important;
  }
}

/* @media screen and (max-width:{
  
} */

.longevity__grid__col{
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.longevity__grid__col .longevity__poster{
  position: sticky;
  top: 100px;
}

.longevity__poster img{
  background: #FFFFFF;
  object-fit: contain!important;
  border-radius: 24px;
  min-height: 350px;
  padding: 20px;
}

.marketplace-rewards__bg{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center -260px;
  z-index: -1;
}

.marketplace-rewards{
  /* background: #FFFF; */
  mix-blend-mode: screen;
  border: 3px solid #fff;
  border-radius: 19px;
  /* 57px; */
  padding: 35px 47px;
  position: relative;
  display: flex;
  gap: 20px;
  z-index: 1;
  overflow: hidden;
  min-height: 191px;
  /* align-items: center; */
  color: #FFFFFF;
  /* background: url('../../assets/img/rewards-bg-full.png'); */
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-size:100% 100%;
}

.marketplace-rewards__title{
  font-size: 18px;
  
  /* font-weight: 600; */
}

.marketplace-rewards__rewards-value{
  font-size: 28px;
  font-weight: 600;
  
}

.marketplace-rewards__rewards-desc{
  font-size: 18px;
  
}

.marketplace-rewards__icon{
  position: absolute;
  right: 47px;
  top: 25px;
  width: 50px;
  height: auto;
}

.marketplace-rewards__img{
  border-radius: 100%;
  width: 115px;
  height: 115px;
}

.longevity__body {
  padding: 128px 0 40px 0;
  /* border-bottom: 1px solid #515156; */
}
.longevity__poster {
  /* margin-bottom: 24px; */
}
.longevity__poster-img {
  display: block;
  width: 100%;
}
.longevity__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 24px; */
}
.longevity__title {
  
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #FFFFFF;
}
.longevity__btns {
  display: flex;
  gap:10px;
  flex-wrap: wrap;
}

.longevity__btns a{
  flex: auto;
}

.longevity__btn {
  
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  padding: 8px 28px 8px 28px;
  display: block;
  background: #4D4E58;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #FFFFFF;
  min-height: 56px;
}
.longevity__btn:not(:last-child) {
  margin-right: 10px;
}
.longevity__btn.active {
  background:linear-gradient(83deg, #122e54, #12488c)
   /* #ffffff; */
}
.longevity__btn.active:hover {
  /* background: rgba(53, 156, 251, 0.7); */
}
.longevity__btn::before {
  font-size: 28px;
  margin-right: 10px;
  color: #FFFFFF;
  /* opacity: 0.5; */
}
.longevity__btn {
  margin-right: 10px;
}
.longevity__btn:hover {
  background: #41424a;
}
.longevity__text {
  /* padding: 39px 0; */
}
.longevity__paragraph {
  
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #C4C4C4;
}
.longevity__paragraph:not(:last-child) {
  margin-bottom: 24px;
}
.longevity__block-title {
  
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #C4C4C4;
  margin-bottom: 8px;
}
.longevity__block-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.longevity__block-price {
  
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 56px;
  color: #FFFFFF;
  margin-bottom: 4px;
}
.longevity__block-link {
  position: relative;
  
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #C4C4C4;
  padding: 0 34px 0 0;
}
.longevity__block-link::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background: url("../../assets/img/link_icon.svg") center no-repeat;
}

.contribution {
  /* padding: 24px 0; */
}
.contribution__body {
  display: flex;
  /* justify-content: space-between; */
  gap: 2rem;
  /* 0px; */
  /* align-items: stretch; */
  /* align-items: flex-end; */
  flex-direction: column;
  padding: 4rem;
  /* 20px; */
  /* padding: 29px 32px; */
  /* background: #363639; */
  border-radius: 24px;
  background-color: #FFFFFF;
}
.contribution__title {
  
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  /* font-size: 48px; */
  /* line-height: 56px; */
  /* color: #FFFFFF; */
  text-transform: uppercase;
}
.contribution__block {
  display: flex;
  align-items: flex-end;
}
.contribution__label {
  display: flex;
  flex-direction: column;
  
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  width: 100%;
  /* color: #FFFFFF; */
  margin-right: 10px;
}
.contribution__input {
  
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 12px 24px;
  background: #515156;
  color: #FFFFFF;
  margin-top: 8px;
  border: 1px solid #515156;
  border-radius: 6px;
}
.contribution__btn {
  
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  padding: 8px 32px 8px 30px;
  display: block;
  background: #4D4E58;
  /* background-image: linear-gradient(83deg, #122e54, #12488c); */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #FFFFFF;
  min-height: 56px;
}
.contribution__currency {
  
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  padding: 16px 32px 16px 32px;
  display: block;
  background: #4D4E58;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #FFFFFF;
  min-height: 56px;
}
.contribution__currency:not(:last-child) {
  margin-right: 10px;
}
.contribution__btn:hover {
  /* background: #41424a; */
}
.contribution__btn:not(:last-child) {
  margin-right: 10px;
}
.contribution__btn.active {
  background-image: linear-gradient(83deg, #122e54, #12488c);
  /* background: #001728; */
}
.contribution__btn.active:hover {
  /* background: rgba(53, 156, 251, 0.7); */
}
.contribution__btn::before {
  font-size: 40px;
  margin-right: 10px;
  color: #FFFFFF;
  /* opacity: 0.5; */
}

.navigation {
  margin-bottom: 24px;
}
.navigation__body {
  /* margin-top: 20px; */
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.navigation__items {
  /* flex: 0 0 31.3%; */
  display: flex;
  position: relative;
  z-index: 2;
}

.navigation__item {
  display: flex;
  
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  background: transparent;
  padding: 16px;
  border-radius: 10px;
  transition: background 0.2s ease, opacity 0.2s ease;
  white-space: nowrap;
}

.navigation__item .info *{
  white-space:word-wrap;
}

.navigation__item:not(:last-child) {
  margin-right: 10px;
}
.navigation__item:hover {
  /* background: #515156; */
  background:linear-gradient(83deg, #fff, #fff);
  color: #000;
  opacity: 0.8;
  border-radius: 10px;
  cursor: pointer;
}
.navigation__item-icon {
  display: inline;
  position: relative;
  padding: 0 28px 0 0;
}
.navigation__item-icon i {
  display: none;
}
.navigation__item-icon::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: url("../../assets/img/info.svg");
  position: absolute;
  top: 0;
  right: 0;
}

.navigation__item:hover .navigation__item-icon:before{
  background: url("../../assets/img/info_black.svg");
}

.navigation__item.active .navigation__item-icon:before{
  background: url("../../assets/img/info_black.svg");
}


.navigation__item.active {
  background:linear-gradient(83deg, #ffffff, #fff);
  color: #000;
  border-radius: 10px;
}

.info {
  position: absolute;
  
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  top: 0;
  right: 0;
  transform: translate(120%, -120%);
  display: block;
  min-width: 260px;
  background: #515156;
  padding: 8px 24px;
  border-radius: 16px;
}

.content {
  padding: 0 0 32px 0;
  min-height: 50vh;
}


.no-data_container{
  width: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  font-weight: 700;
  padding-top: 100px;
  padding-bottom: 100px ;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

.profile-no-data_container{
  min-height: 20vh;
}

.nft__title {
  
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #FFFFFF;
  margin-bottom: 10px;
}
.nft__list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.nft__item {
  flex: 0 0 13.5%;
  /* border-radius: 16px; */
  width: 186.225px;
  /* height: 191px; */
  overflow: hidden;
  margin: 5px;
  color: #ffff;
  text-align: center;
}

.table {
  width: 100%;
  /* border-collapse: separate; */
  border-spacing: 0 12px;
  border-collapse: collapse;
}
.table__head {
  background: #515156;
}
.table__row.active td:not(:first-child) {
  color: #ffffff;
}

.table__row.comming{
  display: flex;
  justify-content: center;
}

.table__column {
  padding: 16px 16px;
  
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 12px;
  border-top: 1px solid #515156;
  border-bottom: 1px solid #515156;
}
.table__column:first-child {
  border-left: 1px solid #515156;
}
.table__column:last-child {
  border-right: 1px solid #515156;
}
@media screen and (min-width: 300px) and (max-width: 1250px) {
  .longevity__body {
    padding-top: 120px;
  }
  .longevity__row {
    flex-direction: column;
  }
  .longevity__title {
    margin-bottom: 24px;
  }
  .longevity__btns {
    width: 100%;
    flex-wrap: wrap;
    /* flex-direction: column; */
  }
  .longevity__btn {
    margin-bottom: 15px;
    padding: 8px 24px;
  }
  .longevity__btn:not(:last-child) {
    margin-right: 0;
  }
  .longevity__block {
    width: 100%;
  }
  .longevity__block-right {
    align-items: flex-start;
    margin-top: 24px;
  }
  .longevity__block-price {
    font-size: 40px;
  }
  .contribution__body {
    flex-direction: column;
    align-items: flex-start;
  }
  .contribution__title {
    font-size: 43px;
    margin-bottom: 25px;
  }
  .contribution__block {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .contribution__label {
    width: 100%;
    margin-bottom: 0px!important;
    margin-right: 0;
  }

  .contribution__block button,
  .contribution__block a{
    margin: 0;
    width: 100%!important;
  }

  .contribution__input {
    width: 100%;
  }
  .contribution__btn {
    width: 100%;
  }
  .contribution__currency {
    width: 100%;
  }
  .contribution__btn:not(:last-child) {
    margin-bottom: 15px;
  }
  .contribution__currency:not(:last-child) {
    margin-bottom: 15px;
  }
  .navigation__body {
    overflow-x: scroll;
  }
  .nft__item {
    flex: 0 1 49%;
  }
}
@media screen and (min-width: 2500px) {
  .longevity__body {
    padding-top: 172px;
  }
  .longevity__title {
    font-size: 70px;
  }
  .longevity__btn {
    font-size: 28px;
    padding: 16px 64px 16px 34px;
  }
  .longevity__btn::before {
    font-size: 48px;
  }
  .longevity__paragraph {
    font-size: 32px;
    line-height: 44px;
  }
  .longevity__block-title {
    font-size: 32px;
    line-height: 44px;
  }
  .longevity__block-price {
    font-size: 63px;
    line-height: 54px;
  }
  .longevity__block-link {
    font-size: 32px;
    margin-top: 10px;
    padding-right: 50px;
  }
  .longevity__block-link::before {
    width: 35px;
    height: 35px;
    background-size: cover;
  }
  .contribution__title {
    font-size: 63px;
    line-height: 73px;
  }
  .contribution__label {
    font-size: 24px;
    margin-right: 25px;
  }
  .contribution__input {
    font-size: 32px;
    padding: 24px 48px;
  }
  .contribution__btn {
    font-size: 24px;
  }
  .contribution__btn:not(:last-child) {
    margin-right: 25px;
  }
  .contribution__btn::before {
    font-size: 65px;
  }
  .navigation__item {
    font-size: 24px;
    line-height: 40px;
  }
  .navigation__item-icon {
    padding: 0 40px 0 0;
  }
  .navigation__item-icon::before {
    background-size: cover;
    width: 40px;
    height: 40px;
  }
  .nft__item {
    background-size: cover;
    height: 286.5px;
    width: 279.3375px;
  }
  .table__column {
    font-size: 24px;
    line-height: 44px;
  }
}

.form__token {
  display: block;
  padding-top: 0;
}
.form__token .form__button{
  margin-top: 20px;
}
@media screen and (min-width: 781px) and (max-width: 1250px) {
  .form__body.form__token-price, .form__body.form__token-exchange, .form__body.form__token-create {
    width: 100%;
  }
}


