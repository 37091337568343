
.claimBtn{
    width: 2000px!important;
    /* width: 220px!important; */
}

.graphicWrap{
    max-height: 0px;
    overflow: hidden;
    overflow-x: auto;
    transition: max-height 0.3s ease-in-out;
    grid-column-start: 1;
    grid-column-end: 3;
}

.graphicWrap.active{
    max-height: 500px;
}

.btnCol{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: flex-end;
}

