.analytics__nav {
  display: flex;
  margin-bottom: 40px;
}
.analytics__nav-block {
  display: flex;
}
.analytics__nav-block:first-child {
  padding-right: 12px;
  margin-right: 24px;
  border-right: 1px solid #3E3E42;
}
.analytics__item {
  
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #6B6B70;
  margin: 0 12px;
}
.analytics__item:first-child {
  margin-left: 0;
}
.analytics__item.active {
  color: #fff;
}
.analytics__item:hover {
  cursor: pointer;
  color: #fff;
}

.analytics__title:first-of-type{
  margin-top: 51px;
}

.analytics__title {
  margin-top: 102px;
  color: #fff;
  text-align: center;
  
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
}
.analytics__subtitle {
  
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #C4C4C4;
  text-align: center;
  /* margin-bottom: 24px; */
  margin-bottom: 10px;
}
.analytics__container {
  width: 100%;
  overflow-x: auto;
  max-width: 100%;
}

.analytics__container::-webkit-scrollbar {
  height: 4px;
}
.analytics__container::-webkit-scrollbar-track {
  background: transparent;
}
.analytics__container::-webkit-scrollbar-thumb {
  background: #918f8f;
  border-radius: 13px;
}

@media screen and (min-width: 300px) and (max-width: 1250px) {
  .analytics__nav {
    overflow-x: auto;
    max-width: 100%;
  }
}


.transactions {
  display: flex;
  flex-direction: column;
  color: #fff;
  
  margin-top: 56px;
}
.transactions__arrow {
  position: relative;
  display: block;
  padding: 8px 16px;
  margin-right: 23px;
}
.transactions__arrow::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
}
.transactions__arrow-down::before {
  background: url("../../../../assets/img/green-arrow-ico.svg") center no-repeat;
}
.transactions__arrow-up::before {
  background: url("../../../../assets/img/gray-arrow-ico.svg") center no-repeat;
}
.transactions__from {
  display: flex;
}
.transactions__from, .transactions__to {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  /* padding-left: 40px; */
}
.transactions__from .gray, .transactions__to .gray {
  color: #C4C4C4;
}
.transactions__from i, .transactions__to i {
  display: block;
  position: relative;
  padding: 0 0 0 24px;
  margin-right: 12px;
  /* height: 24px; */
}
.transactions__from i::before, .transactions__to i::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: url("../../../../assets/img/link_icon.svg") center no-repeat;
  top: 0;
  left: 0;
  position: absolute;
}
.transactions__from:hover, .transactions__to:hover {
  cursor: pointer;
}
.transactions__date {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.transactions__link {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  position: relative;
  padding-left: 00px;
  display: flex;
}
.transactions__link i {
  display: block;
  padding: 0 0 0 24px;
  margin-right: 12px;
  height: 24px;
  position: relative;
}
.transactions__link i::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: url("../../../../assets/img/share-link-ico.svg") center no-repeat;
  top: 0;
  left: 0;
  position: absolute;
}
.transactions__item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.transactions__item-content {
  display: grid;
  grid-template-columns: auto 160px auto 130px auto;
  /* repeat(5,1fr); */
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 8px 16px;
  border-bottom: 1px solid rgba(81, 81, 86, 0.4);
}
.transactions__value {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.transactions__column {
  display: flex;
  /* justify-content: center; */
  flex: 0 1 278px;
  flex-wrap: wrap;

}

@media screen and (min-width: 300px) and (max-width: 1250px) {
  .analytics__nav {
    overflow-x: auto;
  }
  .transactions__item {
    margin-bottom: 16px;
  }
  .transactions__item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    gap: 0;
  }
  .transactions__item-content > span, .transactions__item-content > a {
    flex: 1 1 auto;
    justify-content: center;
    border-top: 1px solid rgba(81, 81, 86, 0.4);
    border-right: 1px solid rgba(81, 81, 86, 0.4);
    padding: 8px;
    width: 100%;
    text-align: center;
  }
  .transactions__arrow {
    margin-right: 0;
    border: 1px solid rgba(81, 81, 86, 0.4);
  }
  .transactions__arrow::before {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .transactions__from i, .transactions__to i, .transactions__link i {
    padding: 0 18px;
  
  }
}

/*distribution*/
.distribution__chart-section:nth-child(0) {
  background: #127156;
}
.distribution__chart-section:nth-child(0):hover {
  cursor: pointer;
  background: #11684f;
}

.distribution__chart-section:nth-child(1) {
  background: #158363;
}
.distribution__chart-section:nth-child(1):hover {
  cursor: pointer;
  background: #137a5d;
}

.distribution__chart-section:nth-child(2) {
  background: #189471;
}
.distribution__chart-section:nth-child(2):hover {
  cursor: pointer;
  background: #168b6a;
}

.distribution__chart-section:nth-child(3) {
  background: #1aa67e;
}
.distribution__chart-section:nth-child(3):hover {
  cursor: pointer;
  background: #199d77;
}

.distribution__chart-section:nth-child(4) {
  background: #1db78c;
}
.distribution__chart-section:nth-child(4):hover {
  cursor: pointer;
  background: #1caf85;
}

.distribution__chart-section:nth-child(5) {
  background: #20c999;
}
.distribution__chart-section:nth-child(5):hover {
  cursor: pointer;
  background: #1fc092;
}

.distribution__chart-section:nth-child(6) {
  background: #23dba6;
}
.distribution__chart-section:nth-child(6):hover {
  cursor: pointer;
  background: #21d2a0;
}

.distribution__chart {
  display: flex;
  margin-bottom: 110px;
  margin-top: 41px;
}
.distribution__chart-section {
  display: block;
  height: 32px;
  flex: 1 1 auto;
  position: relative;
}
.distribution__chart-section:first-child {
  background: #127156;
}
.distribution__chart-section span {
  display: block;
  
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  bottom: -6px;
  left: 0;
  color: #fff;
  transform: translateY(100%);
}
.distribution__chart-section:last-child::before {
  content: none;
}

.information {
  display: flex;
  justify-content: center;
  margin-bottom: 68px;
}
.information__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  font-style: normal;
  padding: 0 250px 95.5px 250px;
  border-bottom: 1px solid #515156;
}
.information__image {
  margin: 16px 0;
  width: 64px;
  text-align: center;
}
.information__label {
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #C4C4C4;
}
.information__sublabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
}
.information__percent {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #fff;
}
.information__sum {
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  color: #ffffff;
}
.information._hide {
  display: none;
}


@media screen and (min-width: 300px) and (max-width: 1250px) {
  .longevity__body {
    padding-top: 120px;
  }
  .longevity__row {
    flex-direction: column;
  }
  .longevity__title {
    margin-bottom: 24px;
  }
  .longevity__btns {
    width: 100%;
    /* flex-direction: column; */
  }
  .longevity__btn {
    margin-bottom: 15px;
    padding: 8px 24px;
  }
  .longevity__btn:not(:last-child) {
    margin-right: 0;
  }
  .longevity__block {
    width: 100%;
  }
  .longevity__block-right {
    align-items: flex-start;
    margin-top: 24px;
  }
  .longevity__block-price {
    font-size: 40px;
  }
  .contribution__body {
    flex-direction: column;
    align-items: flex-start;
  }
  .contribution__title {
    font-size: 43px;
    margin-bottom: 25px;
  }
  .contribution__block {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .contribution__label {
    width: 100%;
    margin-bottom: 15px;
  }
  .contribution__input {
    width: 100%;
  }
  .contribution__btn {
    width: 100%;
  }
  .contribution__btn:not(:last-child) {
    margin-bottom: 15px;
  }
  .navigation__body {
    overflow-x: scroll;
  }
  .nft__item {
    flex: 0 1 49%;
  }
  .analytics__nav {
    overflow-x: auto;
  }
  .transactions__item {
    margin-bottom: 16px;
  }
  .transactions__item-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .transactions__item-content > span, .transactions__item-content > a {
    flex: 1 1 auto;
    justify-content: center;
    border-top: 1px solid rgba(81, 81, 86, 0.4);
    border-right: 1px solid rgba(81, 81, 86, 0.4);
    padding: 8px;
    width: 100%;
    text-align: center;
  }
  .transactions__nav {
    transform: translateY(0);
  }
  .transactions__arrow {
    margin-right: 0;
    border: 1px solid rgba(81, 81, 86, 0.4);
  }
  .transactions__arrow::before {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .transactions__from i, .transactions__to i, .transactions__link i {
    padding: 0 18px;
  }
}

@media screen and (min-width: 2500px) {
  .longevity__body {
    padding-top: 172px;
  }
  .longevity__title {
    font-size: 70px;
  }
  .longevity__btn {
    font-size: 28px;
    padding: 16px 64px 16px 34px;
  }
  .longevity__btn::before {
    font-size: 48px;
  }
  .longevity__paragraph {
    font-size: 32px;
    line-height: 44px;
  }
  .longevity__block-title {
    font-size: 32px;
    line-height: 44px;
  }
  .longevity__block-price {
    font-size: 63px;
    line-height: 54px;
  }
  .longevity__block-link {
    font-size: 32px;
    margin-top: 10px;
    padding-right: 50px;
  }
  .longevity__block-link::before {
    width: 35px;
    height: 35px;
    background-size: cover;
  }
  .contribution__title {
    font-size: 63px;
    line-height: 73px;
  }
  .contribution__label {
    font-size: 24px;
    margin-right: 25px;
  }
  .contribution__input {
    font-size: 32px;
    padding: 24px 48px;
  }
  .contribution__btn {
    font-size: 24px;
  }
  .contribution__btn:not(:last-child) {
    margin-right: 25px;
  }
  .contribution__btn::before {
    font-size: 65px;
  }
  .navigation__item {
    font-size: 24px;
    line-height: 40px;
  }
  .navigation__item-icon {
    padding: 0 40px 0 0;
  }
  .navigation__item-icon::before {
    background-size: cover;
    width: 40px;
    height: 40px;
  }
  .nft__item {
    background-size: cover;
    height: 286.5px;
    width: 279.3375px;
  }
  .table__column {
    font-size: 24px;
    line-height: 44px;
  }
}


.contributors__list {
  display: flex;
  justify-content: center;
  margin-top: 36px;
  margin-bottom: 41px;
}
.contributors__item {
  
}
.contributors__item:not(:last-child) {
  margin-right: 40px;
}
.contributors__sum {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}
.contributors__label {
  display: flex;
  margin-bottom: 2px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #C4C4C4;
  position: relative;
  padding-right: 32px;
}
.contributors__label:hover {
  cursor: pointer;
}
.contributors__label::before {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  background: url("../../../../assets/img/link_icon.svg") center no-repeat;
  transform: translateY();
}


.contributors__link-wrap{
  display: flex;
  justify-content: center;
}

.contributors__link {
  display: block;
  margin: 0 auto;
  text-align: center;
  
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  color: #fff;
}

@media screen and (min-width: 300px) and (max-width: 1250px) {
  .contributors__list {
    flex-direction: column;
    align-items: center;
  }
  .contributors__item {
    display: flex;
    justify-content: space-between;
  }
}/*# sourceMappingURL=donut_chart.css.map */

.navigation__body::-webkit-scrollbar {
  height: 4px;
}
.navigation__body::-webkit-scrollbar-track {
  background: transparent;
}
.navigation__body::-webkit-scrollbar-thumb {
  background: #918f8f;
  border-radius: 13px;
}