.crcl_animate {
    position: relative;
    min-width: 220px;
    /* width: 100px; */
    height: 56px;
    border-radius: 10px;
    z-index: 1;
    display: inline-block;
    /* overflow: hidden; */
    box-sizing: border-box;
  }
  .crcl_animate:before, .crcl_animate:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* transform: translate(-55%, -25%); */
    /* background-color: red; */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #00000065), color-stop(40%, #0000007b), color-stop(80%, #00000074));
    /* -webkit-gradient(linear, left top, right top, color-stop(-20%, #04a3a0), color-stop(40%, #001729), color-stop(100%, #05abe0)); */
    border-radius: 10px;
    background-size: 300%;
    width: calc(100%);
    height: calc(100%);
    /* filter: blur(1px); */
    z-index: -1;
    background-position: -112% 0;
    /* rotate: 15deg; */
    transition: background-position 1.8s ease;
  }
  .crcl_animate:hover:before, .crcl_animate:hover:after {
    background-position: -262.5% 0;
  }

  .disabled.crcl_animate:hover:before,
  .disabled.crcl_animate:hover::after{
    background-position: -112% 0;
  }

  .crcl_animate:after {
    filter: blur(2vh);
  }

  .crcl_animate_inner{
    display:flex;align-items:center; justify-content:center;height:calc(100% - 4px); margin:2px;
    /* background:linear-gradient(83deg, #122e54, #12488c); */
    border-radius:8px;
    color:#FFF;
    
    letter-spacing: 0.4px;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    /* text-transform: uppercase; */
    font-size: 18px;
    line-height: 32px;
    padding: 1.5rem 2rem;
    color: #000;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  

  .primary .crcl_animate_inner{
    background: white;
    /* background:linear-gradient(83deg, #122e54, #12488c); */
  }

  .secondary .crcl_animate_inner{
    background-color: #000;
    color:#fff;
    border: 1px solid rgba(255, 255, 255,1);
  }
  .third .crcl_animate_inner{
    /* background-color: #001728; */
    /* border: 1px solid #12488c; */
  }
  
  .white .crcl_animate_inner{
    background: white;
    color: #5b5b5b
  }


  .crcl_animate_hover_label{
    display: none;
  }

  .crcl_animate_main_label{
    display: flex;
    gap: 10px;
    align-items: center;
    vertical-align: center;
  }

  .crcl_animate:hover .crcl_animate_main_label{
    display: none;
  }

  .crcl_animate:hover .crcl_animate_hover_label{
    display: flex;
    gap: 10px;
    align-items: center;
  }



  .fifth .crcl_animate_inner{
    background-color: transparent;
    color:#fff;
    border: 1px solid rgba(255, 255, 255,1);
  }

  .fifth.crcl_animate:before,
  .fifth.crcl_animate:after{
    content: none;
  } 