.row {
	display: flex;
	flex-wrap: wrap;
	padding: 0 4px;
}

/* Four column layout */
.column {
	flex: 25%;
	max-width: 25%;
	padding: 0 4px;
}

.column img {
	margin-top: 8px;
	vertical-align: middle;
}

/* Two column layout */
@media (max-width: 800px) {
	.column {
		flex: 50%;
		max-width: 50%;
	}
}

/* One column layout */
@media (max-width: 600px) {
	.column {
		flex: 100%;
		max-width: 100%;
	}
}


.monsary > div {

}


.img {
    display: block;
    width: 100%;
    border-radius: 16px;
}

.img::after {
    content: attr(alt);  /* Display alt text content */
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* Set background color */
    padding: 10px; /* Add padding for better visibility */
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

