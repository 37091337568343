.wallets {
  width: 100%;
  min-height: 100vh;
  padding: 128px 0 328px 0;
  position: relative;
  z-index: 1;
}




.wallets__body {
  position: relative;
}
.wallets__title {
  
  font-style: normal;
  font-size: 17.555px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  /* font-weight: 700; */
  /* font-size: 48px; */
  /* line-height: 56px; */
  color: #FFFFFF;
  /* margin-bottom: 24px; */
}

/* .wallets__list-outer{
  width: 100%;
} */

.wallets__list-wrap{
  container: sidebar / inline-size;
  /* width: 100%; */
}




@container sidebar (width > 800px) {
  .wallets__item{
    width: 33.33%!important;
  }
}

@container sidebar (width > 1000px) {
  /* .wallets__list{
    width: 300px;
  } */

  .wallets__item{
    width: 25%!important;
  }
}


@container sidebar (width < 800px) {
  /* .wallets__list{
    width: 300px;
  } */

  .wallets__item{
    width: 50%!important;
  }
}

@container sidebar (width < 500px) {
  /* .wallets__list{
    width: 300px;
  } */

  .wallets__item{
    /* width: 100%!important; */
  }
}

.wallets__list-outer{
  width: calc(100% - 370px);
  transition: width 0.3s ease-out;
}

.wallets__list {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  align-items: flex-start;
  gap: 20px;  
  min-height: 50vh;
  flex-wrap: wrap;
  z-index: 10;
  /* gap:14px; */
  position: relative;
  justify-content: flex-start;
  margin-left: -14px;
  margin-right: -14px;
  

}

.wallets__list .no-data_container{
  grid-column-start: 1;
  grid-column-end: 4;
}
.wallets__item {
  position: relative;

  /* padding: 14px; */
  /* width: 33.33%; */
  /* flex-basis: 25%; */

  /* flex: 0 1 24%; */
  /* display: flex; */
  /* display: flex;
  padding: 24px 48px 24px 24px;
  flex: 0 1 24%;
  border-radius: 16px;
  background: #29292E; */
  /* margin-bottom: 10px; */
}
.wallets__item-inner{
  position: relative;
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.14);
  /* flex-direction: column; */
  padding: 20px;
  /* align-items: center; */
  /* padding: 23px 30px 30px 19px; */
  /* padding: 24px 48px 24px 24px; */
  /* flex: 0 1 24%; */
  border-radius: 16px;
  /* background: #FFF; */
  height: 100%;
  /* border: 3px solid rgb(39, 62, 93, 1) ; */
   /* rgba(255, 255, 255, 0.4); */
   /* box-shadow: inset 0px 0px 15px 2px rgba(39, 62, 93, 0.3); */
   /* transition: all 0.3s ease-in-out; */
  /* box-shadow: inset 0px 0px 15px 2px rgba(255, 255, 255, 0.2) */
  /* box-shadow: inset 0 0px 10px rgba(255, 255, 255, 0.2); */
  /* gap: 20px; */
}

.wallets__item-price{
  /* color:#12488c; */
  color: #FFF;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 14px;
}

.page_header{
  position: relative;
  z-index: 1;
}

.page_header::before{
  content: "PrivateAI";
  white-space: nowrap;
  font-size: 12vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  color: #fff;
  opacity: 0.2;
  filter: blur(10px);
  text-transform: uppercase;
}

.marketplace-rewards{
  box-shadow: inset 0px 0px 15px 2px rgba(39, 62, 93, 0.3);
  margin-bottom: 10vh;
}

.wallets__item:hover .wallets__item-inner{
  box-shadow: inset 0px 0px 15px 2px rgba(255, 255, 255, 0.4)
}

.wallets_item_left-col{
  min-width:100px ;
  /* min-height: 100px; */
  margin-right: 20px;
  /* width: 40%;   */
  /* max-width: 200px; */
}

.wallets_item_left-col_featured-img{
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.wallets_item_left-col_featured-img img{
  scale: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  /* height: 400px; */
  object-fit: contain;
  transition: scale .3s ease-out;
}

.wallets__item:hover .wallets_item_left-col_featured-img img{
  /* scale: 1.1; */
}



/* .wallets_item_left-col_featured-img img:hover{
  scale: 1.1;
} */
/* .wallets__item
:not(:nth-child(5n+5), :first-child) {
  margin-left: 6px;
  margin-right: 6px;
} */


.wallets__item-inner::before {
  content: "";
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  /* background: radial-gradient(80.69% 208.78% at 108.28% 112.58%, #FFFFFF 0%, rgba(226, 226, 226, 0) 100%); */
  /* background: linear-gradient(#ffffff, #FFFFFF); */
  border-radius: 16px;
  z-index: -1;
}

/* .wallets__item::before {
  content: "";
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background: radial-gradient(80.69% 208.78% at 108.28% 112.58%, #FFFFFF 0%, rgba(226, 226, 226, 0) 100%);
  background: linear-gradient(#ffffff, #FFFFFF);
  border-radius: 16px;
  z-index: -1;
} */
.wallets__item-block {
  display: flex;
  flex-direction: column;
}
.wallets__item-title {
  word-break: break-all;
  
  font-style: normal;
  font-weight: 600;
  /* font-weight: 700; */
  /* font-size:2.313rem ; */
  /* font-weight: 600; */
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 0; 
  color: #FFFFFF;
}



.wallets__item-description {
  
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* color: #828282; */
  color: #FFFFFF;
  opacity: 0.8;
  overflow-wrap: anywhere;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wallets__image {
  display: block;
  /* margin-right: 20px; */
  object-fit: contain;
}

.wallet__grid-content{
  display: flex;
  position: relative;
  /* display: grid; */
  /* grid-template-columns: auto 0.25fr; */

  /* gap: 20px; */
  /* padding: 0 20px; */
}



.wallet_grid-aside_sticky-wrap{
  position: sticky;
  top: 100px;
}

.wallet_grid-aside{
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  padding: 14px 0;
  z-index: 11;
  width: 340px;
  transform: translateX(0%);
  transition: all 0.3s ease-out;
  display: none;
  /* order: 2; */
}

.aside_btn{
  transition: all 0.3s ease-out;
}

.wallet__grid-content.active .wallet_grid-aside .aside_btn{
  margin-left: 0px;
  transform: translateX(-100%);
}

.wallet__grid-content.active .wallet_grid-aside{
  transform: translateX(100%);
}

/* .wallet__grid-content.active .wallets__list, */
.wallet__grid-content.active .wallets__list-outer{
  width: calc(100% - 60px);
  
}

.wallet_grid-aside::before{
  content: "";
  position: absolute;
  background-color: #001728;
  transform: translateX(100%);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  transition: transform .3s ease-out;
}

.wallet__grid-content.active .wallet_grid-aside::before{
  transform: translateX(0%);
}

.wallets__item-social {
  
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #828282;
  display: flex;
  flex-flow: row;
  justify-content: center;
  grid-gap: 10px;
  padding-top: 14px;
  mix-blend-mode: exclusion;
  /* margin-bottom: 14px; */
  /* justify-content: center; */
}

@media screen and (min-width: 300px) and (max-width: 780px) {
  .wallets__list {
    grid-template-columns: 1fr;
    min-height: auto;
    /* flex-direction: column; */
    /* flex-wrap: nowrap; */
  }
  .wallets__item {
    width: 100%;
    margin-bottom: 15px;
  }
  .vertical__text {
    display: none;
  }
  .gradient_rectangle_line {
    display: none;
  }
}
@media screen and (min-width: 781px) and (max-width: 1250px) {
  .wallets__list {
    grid-template-columns: 1fr 1fr;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .wallets__item {
    /* width: 50%; */
    /* flex-basis: 50%; */
    /* flex: 0 1 48%; */
  }
  .vertical__text {
    display: none;
  }
}
@media screen and (min-width: 2500px) {
  .wallets {
    padding-top: 200px;
  }
  .wallets__title {
    font-size: 63px;
  }
  .wallets__list {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .wallets__item-title {
    font-size: 32px;
    line-height: 44px;
  }
  .wallets__item-description {
    font-size: 24px;
    line-height: 42px;
  }
}

.gradient_rectangle_line {
  width: 25%;
  /* background: linear-gradient(264.23deg, #00C4B8 4.42%, #0ACE88 98.24%); */
  /* background: linear-gradient(264.23deg, rgba(53, 156, 251, 1) 4.42%, rgba(53, 156, 251, 0.75) 98.24%)!important; */
  background: white!important;
  opacity: 0.8;
  position: fixed;
  height: 100vh!important;
  top: 0;
  right: 0;
}

@media screen and (max-width: 768px) {

  .marketplace-rewards{
    padding: 25px!important;
  }

  .marketplace-rewards__icon{
    right: 5px!important;
    top: 20px;
  }

  .marketplace-rewards{
    display: grid!important;
    grid-template-columns: repeat(2,auto);
    flex-direction: column;
    gap: 20px!important;
  }

  .marketplace-rewards svg{
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .marketplace-rewards .col:first-of-type{
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* gap: 20px; */
    /* align-items: center; */
    margin-top: -20px;
    /* width: 100%; */
  }

  .wallet__grid-content{
    display: grid;
    grid-template-columns: 1fr;
  }

  .aside_btn{
    display: none!important;
  }

  .wallet_grid-aside{
    position: relative;
    width: 100%;
  }
  .wallets__list-outer{
    width: 100%!important;
  }

  .wallets__item{
    padding: 7px;
    margin: 0;
  }

  .wallets__list{
    margin: -7px;
  }
}
@media screen and (max-width:510px){
  .marketplace-rewards .col{
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .marketplace-rewards .col:last-of-type,
  .marketplace-rewards .col:last-of-type a{
    width: 100%;
  }
}


.wallet__row{
  
}

.wallet__head{
  display: flex;
  justify-content: space-between;
  gap:30px;
  /* flex-wrap: wrap; */
  align-items: flex-end;
  border-bottom: 3px solid #fff;
  padding: 20px 0;
}

.wallet__head_title{
  color: #FFFFFF;
  font-size: 2.4vmax;
  font-family: "Satoshi";
  line-height: 1.2;
  font-weight: 600;
  position: relative;
  text-transform: capitalize;
}

.wallet__head_title span{
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(100%, 0%);
  width: 20px;
  height: 20px;
  /* border: 1px solid #1CAF85; */
  border-radius: 100%;
}

.wallet__head_title span svg path{ 
  fill:#12488c
}


.create_dao_btn{
  color: #12488c;
  /* font-size: 1.3vmax; */
  font-family: "Satoshi";
  line-height: 1.2;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  gap: 7px;
  align-items: center;
}

.wallet__btn{
  color: #fff;
  font-family: "Satoshi";
  line-height: 1.2;
  font-weight: 600;
}

.wallet__btn_list{
  display: flex;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.wallet_content{
  padding: 30px 0;
  position: relative;
}

.wallet_content__no_data{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #FFF;
  
  font-weight: 600;

}

.wallet__right_col{
  margin-left: auto;
}

.wallet_content .wallet_content_list{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap:20px;
  
}

@media screen and (max-width:900px) {
  .wallet_content .wallet_content_list{
    grid-template-columns: repeat(2,1fr);
  }

  .wallet__head{
    align-items: flex-start;
  }

  .wallet__btn_list{
    /* flex-direction: column; */
    /* align-items: flex-start; */
  }
  
  .wallet__btn_list li{
    height: 2.88vmax;
    display: flex;
    align-items: center;
  }

  .wallet__right_col{
    margin-left: 0;
  }
}

@media screen and (max-width:600px) {
  .wallet_content .wallet_content_list{
    grid-template-columns: 1fr;
  }
}