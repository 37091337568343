.profile__body {
  padding: 128px 0 0px 0;
  color: white;
  position: relative;
  z-index: 1;
}
.info-wrapper {
  border: 1px solid #e2e8f0;
  width: 100%;
  border-radius: 12px;
  padding: 24px 0;
  min-height: 104px;
  display: flex;
  align-items: center;
  border: none;
  border-width: none;
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .info-wrapper {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .info-wrapper img{
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.update-profile {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 24px;
  grid-column: 1/-1;
}

@media  screen and (max-width: 768px) {
  .update-profile {
    grid-template-columns: none;
  }
}