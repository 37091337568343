.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* padding: 24px 0; */
  background: rgba(255, 255, 255, 0.1);
  /* backdrop-filter: blur(22px); */
  /* -webkit-backdrop-filter: blur(22px); */
  z-index: 15;
}

.header__logo{
  display: inline-flex;
}

.not-active{
  position: relative;
  /* padding-right: calc( 25px); */
}

.header__btn.not-active{
  cursor: not-allowed;
}

.not-active > .keylock{

  position: absolute;
  /* top: 50%; */
  bottom: 0%;
  right: 0;
  transform: translate(12px, -100%);
}

.header__burger {
  position: relative;
  display: block;
  width: 60px;
  height: 35px;
  background: transparent;
}

.header__burger:hover {
  cursor: pointer;
}

.header__burger span {
  transform-origin: center;
  display: block;
  background: #fff;
  width: 100%;
  height: 3px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.8s;
}

.header__burger::after,
.header__burger::before {
  transition: all 0.8s;
  transform-origin: center;
  content: "";
  width: 100%;
  height: 3px;
  background: #fff;
  position: absolute;
}

.header__burger::after {
  top: 0;
}

.header__burger::before {
  bottom: 0;
}

.header__burger.active span {
  transform: scale(0.2) translateY(-50%);
  opacity: 0;
}

.header__burger.active::after {
  top: 50%;
  transform: rotate(45deg);
}

.header__burger.active::before {
  top: 50%;
  transform: rotate(-45deg);
}

.header__body {
  display: grid;
  /* justify-content: space-between; */
  align-items: center;
}

.header__title {

  font-family: "Saira Semi Condensed"!important;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  font-size: 19.059px!important;
  line-height: 32px;
  color: #FFFFFF;
}

.header__btns {
  display: flex;
  align-items: center;
  position: relative;
}

.header__btn {
  border-radius: 100px;
  position: relative;
  display: block;
}

.disabled.header__btn{
  cursor: not-allowed;
}

.disabled.header__btn .hover{
  display: none;
}

.disabled.header__btn:hover .main_link{
  display: none;
}

.disabled.header__btn:hover .hover{
  display: block;
}

.header__btn {
  margin-right: 24px;
  /* margin-right: 16px; */
}

.header__btn-border {
  
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 24px;
  background: transparent;
  padding:8px 45px 8px 23px ;
  /* padding: 10px 61px 10px 24px; */
}

.header__btn-border:hover {
  color: #e6e6e6;
  border: 1px solid red #e6e6e6;
}

.header__btn-border::after {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 24px;
  background: url("../../assets/img/arrow_down.svg") center no-repeat;
  transform: translateY(-50%);
}

.header__btn-fill {
  text-transform: uppercase;
  background: #fff;
  padding: 10px 63px 10px 35px;
  display: flex;
  align-items: center;
}

.header__btn-fill::before {
  font-size: 28px;
  color: #515156;
}

.header__btn-fill span {
  
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #4D4E58;
  font-size: 12px;
  line-height: 20px;
}

.header__btn-fill:hover {
  background: #e6e6e6;
}

.header__btn span {
  
  font-size: 16.462px;
  /* font-size: 16px; */
  /* font-weight: bold; */
}

.header__btn span.active {
  /* color: #ffffff */
  font-weight: 700;
}



.header__dropdown {
  position: absolute;
  min-width: 266px;
  top: 100%;
  left: 50%!important;
  transform: translate(-50%,10px)!important;
  /* left: 27% !important; */
  background: #515156;
  border-radius: 8px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.12);
  padding: 8px 0;
  opacity: 1;
  transition: all 0.2s;
}

.hide {
  display: none
}

;

.show {
  display: block
}

a.header__btn {
  color: white;
  cursor: pointer;
}



@media screen and (min-width: 1250px) {
  a.header__btn:hover {
    scale: 1.12;
  }  
}

.header__dropdown-item {
  
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 8px 24px;
  background: #515156;
}

.header__dropdown-item:hover {
  cursor: pointer;
  background: #28282d;
}

.header__dropdown.active {
  transform: translateY(10%);
  opacity: 1;
}

.header__btn_switch_wrap{
  position: relative;
}


.vertical__text {
  position: absolute;
  font-family: "Tomorrow";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  font-size: 120px;
  line-height: 96px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #FFFFFF;
  z-index: 1;
  
}

.main .vertical__text {

  height: 100vh;
  overflow: hidden;
  white-space: nowrap;
}

.bottom_to_top {
  writing-mode: vertical-lr;
  top: 0;
  left: 0;
  transform-origin: center;
  transform: rotate(180deg);
}

.top_to_bottom {
  writing-mode: vertical-rl;
  right: 0;
  top: 0;
}

@media screen and (min-width: 300px) and (max-width: 1250px) {
  ._pc {
    display: none !important;
  }

  .header__btn_switch_wrap{
    margin-top: 20px;
    width: 80%;
    display: flex;
    flex-direction: column;
  }

  .header__btn_switch_wrap *{
    width: 100%!important;
  }

  .header__btn_switch_wrap ul{
    left: 0!important;
    transform: unset!important;

  }

  ._mob {
    display: block !important;
  }

  .container {
    width: 95%;
  }

  .header {
    /* background: rgba(255, 255, 255, 0.4); */
  }

  .header__body {
    position: relative;
  }

  .header__title {
    width: 100%;
    text-align: center;
  }

  .header__dropdown {
    position: relative;
    left: 0;
    width: 80%;
    display: none;
    transition: all 0.5s;
    box-shadow: none;
  }

  .header__dropdown.active {
    display: block;
  }
  

  .header__btns {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #515156;
    top: 75px!important;
    width: 100%;
    opacity: 0;
    transition: all 0.2s;
    display: none;
    left: -2.6%;
    padding: 50px 0;
  }

  .header__btns.active {
    display: flex;
    opacity: 1;
  }

  .header__btn {
    width: 80%;
    font-size: 18px;
    margin: 0;
    margin-top: 20px;
  }

  .header__btn:first-child {
    margin: 0;
  }

  .vertical__text {
    display: none;
  }
}

@media screen and (min-width: 781px) and (max-width: 1250px) {
  ._pc {
    display: block !important;
  }

  ._mob {
    display: none !important;
  }
}

@media screen and (min-width: 2500px) {
  .header {
    padding: 44px;
  }

  .header__title {
    font-size: 44px;
  }

  .header__dropdown {
    left: -15%;
  }

  .header__dropdown-item {
    font-size: 32px;
    line-height: 44px;
    padding: 16px 217px 16px 60px;
  }

  .header__btn-border {
    padding: 20px 70px 20px 50px;
    font-size: 32px;
  }

  .header__btn-border::after {
    background-size: cover;
    width: 44px;
    height: 44px;
    transform: translateY(30%);
  }

  .header__btn-fill {
    padding: 20px 50px;
  }
  

  .header__btn-fill::before {
    font-size: 44px;
    margin-right: 10px;
  }

  .header__btn-fill span {
    font-size: 32px;
  }
}